import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AutoOrder from '../pageBuilders/autoOrder';
import Layout from '../components/layout';
import { GetInsightsDataQuery } from '../../graphql-types';

const SEO = {
  'title': 'Insights',
  'author': 'Deutsch LA',
  'keywords': 'deutsch, articles',
};

const GET_INSIGHTS_DATA = graphql`
  query GetInsightsData {
    allNodeBodyBuilder(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteBodyBuilderItems
    }
    allNodeMasthead(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteMastheadsFields
    }
    allNodeTout(filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }) {
      ...CompleteToutFields
    }
    allNode2UpGrid(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...Complete2upGridFields
    }
    allNode3UpInformative(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...Complete3UpInformativeFields
    }
    allNodeToutText(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteToutTextFields
    }
    allNodeContentAdvertise(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteContentAdvertiseFields
    }
    allNodeQuote(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteQuoteFields
    }
    allNodeToutSimple(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteToutSimpleFields
    }
    allNodeSimpleMedia(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteSimpleMediaFields
    }
    allNodeMediaGrid(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteMediaGridFields
    }
    allNodeCarousel(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteCarouselFields
    }
    allNodeArticleTout(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteArticleToutFields
    }
    allNodeBodyContent(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteBodyContentFields
    }
    allNodeDownloadableContent(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteDownloadableContentFields
    }
    allNodeQuickLi(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteQuickLinksFields
    }
    allNodeNewsGrid(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteNewsGridFields
    }
    allNodeArticlesGrid(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteArticlesGridFields
    }
    allNodeTout5050(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteTout5050Fields
    }
    allNodeMasthead2022(
      filter: { relationships: { field_page_reference: { name: { eq: "Insights" } } } }
    ) {
      ...CompleteMasthead2022Fields
    }
  }
`;

const LandingPage: React.FC = () => {
  const data = useStaticQuery<GetInsightsDataQuery>(GET_INSIGHTS_DATA);

  return (
    <Layout seo={SEO}>
      <AutoOrder data={data} className="pb-18" id="insights" />
    </Layout>
  );
};

export default LandingPage;
